var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButton',{attrs:{"text":"Novo combo promocional","type":"primario"},on:{"click":_vm.onNew}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"name","sortDesc":true,"search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"vigencia_inicial",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.renderData(col))+" ")]}},{key:"vigencia_final",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.renderData(col))+" ")]}},{key:"disponivel",fn:function(ref){
var col = ref.col;
return [_vm._t("option",[(col)?_c('div',[_c('span',{staticClass:"label-disponivel label-success"},[_vm._v("Sim")])]):_c('div',[_c('span',{staticClass:"label-disponivel label-info"},[_vm._v("Não")])])],{"option":col})]}},{key:"idcombopromocional",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}],null,true)})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }