<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Novo combo promocional"
            type="primario"
            @click="onNew"
          />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="name"
        :sortDesc="true"
        @click="onClick"
        search
        ref="datatable"
      >
        <template v-slot:vigencia_inicial="{ col }">
          {{ renderData(col) }}
        </template>
        <template v-slot:vigencia_final="{ col }">
          {{ renderData(col) }}
        </template>
        <template v-slot:disponivel="{ col }">
          <slot name="option" :option="col">
            <div v-if="col">
              <span class="label-disponivel label-success">Sim</span>
            </div>
            <div v-else>
              <span class="label-disponivel label-info">Não</span>
            </div>
          </slot>
        </template>
        <template v-slot:idcombopromocional="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import DataTable from '@/components/DataTable'
import FormButton from '@/components/form/FormButton'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import { COMBO_PROMOCIONAL_REMOVE } from '@/store/actions/comboPromocional'
import { SETOR_FOLHA_LOAD } from '@/store/actions/setor'
import { SEGMENTO_LIST } from '@/store/actions/segmento'
import { mapGetters } from 'vuex'
import moment from 'moment'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'ComboPromocionalTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonGroup,
    FormButtonMenu,
    MarketingModules
  },
  data: () => ({
    headers: [
      {
        text: 'Nome',
        value: 'nome'
      },
      {
        text: 'Descrição',
        value: 'descricao'
      },
      {
        text: 'Data inicial',
        value: 'vigencia_inicial',
        align: 'center'
      },
      {
        text: 'Data final',
        value: 'vigencia_final',
        align: 'center'
      },
      {
        text: 'Código interno',
        value: 'id_combopromocional_erp',
        align: 'center'
      },
      {
        text: 'Disponível',
        value: 'disponivel',
        align: 'center'
      },
      {
        text: 'Opções',
        value: 'idcombopromocional',
        clickable: false,
        align: 'center'
      }
    ],
    tableOptions: [
      { text: 'Editar', value: 'update' },
      { text: 'Deletar', value: 'delete' }
    ],
    modulosForm: [ModulesEnum.WEB_COMMERCE, ModulesEnum.COMMERCE_APP]
  }),
  created() {
    this.$store.dispatch(SEGMENTO_LIST)
    this.$store.dispatch(SETOR_FOLHA_LOAD)
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'getEmpresaId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/promotional-combos/${this.getFornecedorId}/${this.getEmpresaId}?fp=todos`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    onClose() {
      this.$refs.datatable.reload()
    },
    onClick(item) {
      this.$router.push(`combos-promocionais/${item.idcombopromocional}`)
    },
    onNew() {
      this.$router.push('combos-promocionais/novo')
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'update':
          return this.onUpdate(item)
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      this.$swal
        .fire({
          title: `Remover combo promocional ? `,
          text: 'Essa ação é irreversível',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) this.delete(item)
          else return
        })
    },
    onUpdate(item) {
      this.$router.push(`combos-promocionais/${item.idcombopromocional}`)
    },
    delete(item) {
      this.$store
        .dispatch(COMBO_PROMOCIONAL_REMOVE, item.idcombopromocional)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Combo promocional removido com sucesso'
          )
          this.$refs.datatable.reload()
        })
    },
    renderData(value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
.label-disponivel {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-success {
  background-color: #62cb31;
}

.label-info {
  background-color: #5bc0de;
}
</style>
